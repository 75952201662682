<template>
    <section id="mainContent" v-if="detail">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <h3 class="card-title" v-if="detail.deviceId">
                                {{ detail.name2 }}
                            </h3>
                            <div class='row'>
                                <div class='col-sm-6 mt-6'>
                                    <form>
                                        <fieldset>
                                            <legend>Appareil</legend>
                                            <!-- MARQUE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Marque <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.brandId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.brandId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(brand, key) in brands'
                                                                    :key='key'
                                                                    :value='brand.brandId'>
                                                                {{ brand.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.brandId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner une marque
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- TYPE PRODUIT -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Type <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.productTypeId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.productTypeId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(productType, key) in productTypes'
                                                                    :key='key'
                                                                    :value='productType.productTypeId'>
                                                                {{ productType.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.productTypeId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner un Type d'appareil
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- MODELE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Modèle <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.modelId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.modelId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(model, key) in models'
                                                                    :key='key'
                                                                    :value='model.modelId'>
                                                                {{ model.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.modelId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner un modèle
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- PRIX ACHAT-->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Prix achat TTC</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='number'
                                                               min='0'
                                                               class='form-control'
                                                               v-model='detail.unitPurchasePrice'
                                                               readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- PRIX ACHAT-->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Prix vente TTC</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='number'
                                                               min='0'
                                                               class='form-control'
                                                               v-model='detail.unitSalePrice'
                                                               readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- COULEUR -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Couleur</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select class='form-control'
                                                                v-model='detail.colorId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(color, key) in colors'
                                                                    :key='key'
                                                                    :value='color.colorId'>
                                                                {{ color.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- CAPACITE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Capacité</label>
                                                <div class='col-6'>
                                                    <div class='row'>
                                                        <div class='col-6 pl-4'>
                                                            <select class='form-control'
                                                                    v-model='detail.romCapacityId'
                                                                    required
                                                                    :disabled='!detail.romUnitId'>
                                                                <option :value="undefined"></option>
                                                                <option v-for='(capacity, key) in capacities'
                                                                        :key='key'
                                                                        :value='capacity.capacityId'>
                                                                    {{ capacity.value }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class='col-6 pr-4'>
                                                            <select class='form-control'
                                                                    v-model='detail.romUnitId'
                                                                    required>
                                                                <option :value="undefined"></option>
                                                                <option v-for='(unit, key) in units'
                                                                        :key='key'
                                                                        :value='unit.unitId'>
                                                                    {{ unit.shortName }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- RAM -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>RAM</label>
                                                <div class='col-6'>
                                                    <div class='row'>
                                                        <div class='col-6 pl-4'>
                                                            <select class='form-control'
                                                                    v-model='detail.ramCapacityId'
                                                                    :disabled='!detail.ramUnitId'>
                                                                <option :value="undefined"></option>
                                                                <option v-for='(capacity, key) in ramCapacities'
                                                                        :key='key'
                                                                        :value='capacity.capacityId'>
                                                                    {{ capacity.value }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class='col-6 pr-4'>
                                                            <select class='form-control'
                                                                    v-model='detail.ramUnitId'
                                                                    required>
                                                                <option :value="undefined"></option>
                                                                <option v-for='(unit, key) in ramUnits'
                                                                        :key='key'
                                                                        :value='unit.unitId'>
                                                                    {{ unit.shortName }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- PRODUIT -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Produit <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.productId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.productId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(product, key) in products'
                                                                    :key='key'
                                                                    :value='product.productId'>
                                                                {{ product.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.productId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner un produit
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                                <div class='col-sm-6 mt-6' v-if='detail.productId'>
                                    <fieldset>
                                        <legend>Informations supplémentaires</legend>
                                        <!-- GENCODE -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Gencode</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='text'
                                                           class='form-control'
                                                           v-model='detail.gencode'
                                                           readonly >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- GRADE -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Etat <abbr>*</abbr></label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <select :class="{'is-invalid': v$.detail.gradeId.$error,}"
                                                            class='form-control'
                                                            v-model='detail.gradeId'
                                                            required>
                                                        <option :value="undefined"></option>
                                                        <option v-for='(grade, key) in grades'
                                                                :key='key'
                                                                :value='grade.gradeId'>
                                                            {{ grade.name }}
                                                        </option>
                                                    </select>
                                                    <p v-if='v$.detail.gradeId.$error'
                                                       class='invalid-feedback'>
                                                        Veuillez sélectionner l'état de l'appareil
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- GENCODE -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Régime TVA</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='text'
                                                           class='form-control'
                                                           v-model='detail.vatRegime'
                                                           readonly >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- SERIAL NUMBER -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Numéro de série / IMEI</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='text'
                                                           class='form-control'
                                                           v-model='detail.serialNumber'>
                                                </div>
                                            </div>
                                        </div>
                                        <!--IMEI2 -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>IMEI 2</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='text'
                                                           class='form-control'
                                                           v-model='detail.imei2'
                                                           readonly >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- FACE ID -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Reconnaissance Faciale</label>
                                            <div class='col-6'>
                                                <div class='row'>
                                                    <div class='col ml-2'>
                                                        <div
                                                            class='custom-control custom-radio custom-control-inline'
                                                            v-for='s in status'
                                                            :key='s.value2'>
                                                            <input class='custom-control-input'
                                                                   type='radio'
                                                                   name='faceID'
                                                                   :id="'faceID'+s.value2"
                                                                   :value='s.value2'
                                                                   v-model='detail.faceId'>
                                                            <label class='custom-control-label'
                                                                   :for="'faceID'+s.value2"
                                                                   :class="detail.faceId === s.value2 ? s.class : ''">{{ s.label2
                                                                   }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- TOUCHE ID -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Lecteur d'empreintes</label>
                                            <div class='col-6'>
                                                <div class='row'>
                                                    <div class='col ml-2'>
                                                        <div
                                                            class='custom-control custom-radio custom-control-inline'
                                                            v-for='s in status'
                                                            :key='s.value2'>
                                                            <input class='custom-control-input'
                                                                   type='radio'
                                                                   name='touchID'
                                                                   :id="'touchID'+s.value2"
                                                                   :value='s.value2'
                                                                   v-model='detail.touchId'>
                                                            <label class='custom-control-label'
                                                                   :for="'touchID'+s.value2"
                                                                   :class="detail.touchId === s.value2 ? s.class : ''">{{ s.label2
                                                                   }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- NFC -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Lecteur NFC</label>
                                            <div class='col-6'>
                                                <div class='row'>
                                                    <div class='col ml-2'>
                                                        <div
                                                            class='custom-control custom-radio custom-control-inline'
                                                            v-for='s in status'
                                                            :key='s.value2'>
                                                            <input class='custom-control-input'
                                                                   type='radio'
                                                                   name='nfc'
                                                                   :id="'nfc_'+s.value2"
                                                                   :value='s.value2'
                                                                   v-model='detail.nfc'>
                                                            <label class='custom-control-label'
                                                                   :for="'nfc_'+s.value2"
                                                                   :class="detail.nfc === s.value2 ? s.class : ''">{{ s.label2 }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--CLIENT -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Client</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='text'
                                                           class='form-control'
                                                           :value='detail.clientPrenom + " " + detail.clientNom'
                                                           readonly >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- DATE PUBLICATION -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Date début publication</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='text'
                                                           class='form-control'
                                                           v-model='detail.publishedDateStart'
                                                           readonly >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- DATE PUBLICATION -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Date fin publication</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='text'
                                                           class='form-control'
                                                           v-model='detail.publishedDateEnd'
                                                           readonly >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- PROPRIETAIRE -->
                                        <div class='row form-group'>
                                            <label class='col-6 col-form-label'>Propriétaire</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='text'
                                                           class='form-control'
                                                           v-model='detail.placeName'
                                                           readonly >
                                                </div>
                                            </div>
                                        </div>
                                        <!-- ETAT -->
                                        <div class='row form-group' v-if="detail.stocks">
                                            <label class='col-6 col-form-label'>Disponible</label>
                                            <div class='col-6'>
                                                <div class='row'>
                                                    <div class='col ml-2'>
                                                        <div
                                                            class='custom-control custom-radio custom-control-inline'
                                                            v-for='s in status'
                                                            :key='s.value'>
                                                            <input class='custom-control-input'
                                                                   type='radio'
                                                                   name='status'
                                                                   :id="'status_'+s.value"
                                                                   :value='s.value'
                                                                   v-model='detail.stocks[0].quantity'
                                                                   disabled>
                                                            <label class='custom-control-label'
                                                                   :for="'status_'+s.value"
                                                                   :class="detail.stocks[0].quantity === s.value ? s.class : ''">{{ s.label2
                                                                   }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- LIEU -->
                                        <div class='row form-group' v-if='detail.stocks'>
                                            <label class='col-6 col-form-label'>Lieu de stockage</label>
                                            <div class='col-6'>
                                                <div class='col-12'>
                                                    <input type='text'
                                                           class='form-control'
                                                           v-model='detail.stocks[0].placeName'
                                                           readonly >
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div class="row form-group justify-content-end">
                                        <div class="col-auto is-animated d-flex">
                                            <button v-if="!loading && detail.deviceId !== null && modification"
                                                    class="btn--color-success"
                                                    @click="openStockModal">
                                                <span>Modifier le lieu</span>
                                                <svg-pin-location />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='card-footer'>
                            <div class='row justify-content-center'>
                                <div class='col-auto'>
                                    <notif type='success'
                                           text='Sauvegarde effectuée'
                                           v-if='success'/>
                                    <notif type='error'
                                           text='Un problème est survenu durant la sauvegarde'
                                           :message='this.msgError'
                                           v-if='error'/>
                                </div>
                            </div>
                            <div class='row is-animated d-flex justify-content-between'>
                                <div class='col-auto'>
                                    <button
                                        v-if='!loading && modification'
                                        class='btn--color-info'
                                        @click='getHistory()'
                                        :disabled='v$.$error'>
                                        <span>Historique</span>
                                        <svg-synchronize-arrow />
                                    </button>
                                </div>
                                <div class='col-auto'>
                                    <button
                                        v-if='!loading && modification'
                                        class='btn--color-success'
                                        @click='submit'
                                        :disabled='v$.$error'>
                                        <span>Sauvegarder</span>
                                        <svg-floppy-disk />
                                    </button>
                                    <button
                                        v-if='loading'
                                        class='btn--color-success'
                                        type='button'
                                        disabled>
                                        <span>Sauvegarde en cours...</span>
                                        <figure class='spin--infinite mr-2'>
                                            <svg-floppy-disk />
                                        </figure>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row mt-3' v-if='detail.deviceId'>
                <div class='col-sm-4'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Image 1</h3>
                            <div class='row justify-content-center'>
                                <div class='col-auto' v-if='detail.image1 && !image.error'>
                                    <img class='img-fluid' :src='detail.image1' >
                                </div>
                                <div class='col-auto' v-if='!detail.image1 || image.error'>
                                    <notif type='error'
                                           :text='image.errorText'/>
                                </div>
                            </div>
                        </div>
                        <!--                        <div class='card-footer'>-->
                        <!--                            <div class='row justify-content-end'>-->
                        <!--                                <div class='col-auto is-animated d-flex'>-->
                        <!--                                    <input type='file' id='fileUpload1' name='fileUpload' @change='handleImage' hidden />-->
                        <!--                                    <button v-if='!image.loading && modification'-->
                        <!--                                            class='btn&#45;&#45;outline'-->
                        <!--                                            @click='selectFile'>-->
                        <!--                                        <span>Modifier l'image</span>-->
                        <!--                                        <svg-download />-->
                        <!--                                    </button>-->
                        <!--                                    <button v-if='image.loading'-->
                        <!--                                            class=''-->
                        <!--                                            type='button'-->
                        <!--                                            disabled>-->
                        <!--                                        <span>Upload en cours...</span>-->
                        <!--                                        <figure class='spin&#45;&#45;infinite ml-2'>-->
                        <!--                                            <svg-download />-->
                        <!--                                        </figure>-->
                        <!--                                    </button>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <div class='row justify-content-end text-demat mr-1 mt-1 font-italic'>-->
                        <!--                                <small>Format: JPG/PNG - Max: 300Ko</small>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                </div>
                <div class='col-sm-4'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Image 2</h3>
                            <div class='row justify-content-center'>
                                <div class='col-auto' v-if='detail.image2 && !image2.error'>
                                    <img class='img-fluid' :src="detail.image2" >
                                </div>
                                <div class='col-auto' v-if='!detail.image2 || image2.error'>
                                    <notif type='error'
                                           :text='image2.errorText'/>
                                </div>
                            </div>
                        </div>
                        <!--                        <div class='card-footer'>-->
                        <!--                            <div class='row justify-content-end'>-->
                        <!--                                <div class='col-auto is-animated d-flex'>-->
                        <!--                                    <input type='file' id='fileUpload2' name='fileUpload' @change='handleImage' hidden />-->
                        <!--                                    <button v-if='!image2.loading && modification'-->
                        <!--                                            class='btn&#45;&#45;outline'-->
                        <!--                                            @click='selectFile2'>-->
                        <!--                                        <span>Modifier l'image</span>-->
                        <!--                                        <svg-download />-->
                        <!--                                    </button>-->
                        <!--                                    <button v-if='image2.loading'-->
                        <!--                                            class=''-->
                        <!--                                            type='button'-->
                        <!--                                            disabled>-->
                        <!--                                        <span>Upload en cours...</span>-->
                        <!--                                        <figure class='spin&#45;&#45;infinite ml-2'>-->
                        <!--                                            <svg-download />-->
                        <!--                                        </figure>-->
                        <!--                                    </button>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <div class='row justify-content-end text-demat mr-1 mt-1 font-italic'>-->
                        <!--                                <small>Format: JPG/PNG - Max: 300Ko</small>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                </div>
                <div class='col-sm-4'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Image 3</h3>
                            <div class='row justify-content-center'>
                                <div class='col-auto' v-if='detail.image3 && !image3.error'>
                                    <img class='img-fluid' :src="detail.image3" >
                                </div>
                                <div class='col-auto' v-if='!detail.image3 || image3.error'>
                                    <notif type='error'
                                           :text='image3.errorText'/>
                                </div>
                            </div>
                        </div>
                        <!--                        <div class='card-footer'>-->
                        <!--                            <div class='row justify-content-end'>-->
                        <!--                                <div class='col-auto is-animated d-flex'>-->
                        <!--                                    <input type='file' id='fileUpload3' name='fileUpload' @change='handleImage' hidden />-->
                        <!--                                    <button v-if='!image3.loading && modification'-->
                        <!--                                            class='btn&#45;&#45;outline'-->
                        <!--                                            @click='selectFile3'>-->
                        <!--                                        <span>Modifier l'image</span>-->
                        <!--                                        <svg-download />-->
                        <!--                                    </button>-->
                        <!--                                    <button v-if='image3.loading'-->
                        <!--                                            class=''-->
                        <!--                                            type='button'-->
                        <!--                                            disabled>-->
                        <!--                                        <span>Upload en cours...</span>-->
                        <!--                                        <figure class='spin&#45;&#45;infinite ml-2'>-->
                        <!--                                            <svg-download />-->
                        <!--                                        </figure>-->
                        <!--                                    </button>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <div class='row justify-content-end text-demat mr-1 mt-1 font-italic'>-->
                        <!--                                <small>Format: JPG/PNG - Max: 300Ko</small>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                </div>
            </div>
            <div class='row mt-3' v-if='detail.deviceId'>
                <div class='col-sm-4'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Image 4</h3>
                            <div class='row justify-content-center'>
                                <div class='col-auto' v-if='detail.image4 && !image4.error'>
                                    <img class='img-fluid' :src='detail.image4' >
                                </div>
                                <div class='col-auto' v-if='!detail.image4 || image4.error'>
                                    <notif type='error'
                                           :text='image4.errorText'/>
                                </div>
                            </div>
                        </div>
                        <!--                        <div class='card-footer'>-->
                        <!--                            <div class='row justify-content-end'>-->
                        <!--                                <div class='col-auto is-animated d-flex'>-->
                        <!--                                    <input type='file' id='fileUpload4' name='fileUpload' @change='handleImage' hidden />-->
                        <!--                                    <button v-if='!image4.loading && modification'-->
                        <!--                                            class='btn&#45;&#45;outline'-->
                        <!--                                            @click='selectFile4'>-->
                        <!--                                        <span>Modifier l'image</span>-->
                        <!--                                        <svg-download />-->
                        <!--                                    </button>-->
                        <!--                                    <button v-if='image4.loading'-->
                        <!--                                            class=''-->
                        <!--                                            type='button'-->
                        <!--                                            disabled>-->
                        <!--                                        <span>Upload en cours...</span>-->
                        <!--                                        <figure class='spin&#45;&#45;infinite ml-2'>-->
                        <!--                                            <svg-download />-->
                        <!--                                        </figure>-->
                        <!--                                    </button>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <div class='row justify-content-end text-demat mr-1 mt-1 font-italic'>-->
                        <!--                                <small>Format: JPG/PNG - Max: 300Ko</small>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                </div>
                <div class='col-sm-4'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Image 5</h3>
                            <div class='row justify-content-center'>
                                <div class='col-auto' v-if='detail.image5 && !image5.error'>
                                    <img class='img-fluid' :src="detail.image5" >
                                </div>
                                <div class='col-auto' v-if='!detail.image5 || image5.error'>
                                    <notif type='error'
                                           :text='image5.errorText'/>
                                </div>
                            </div>
                        </div>
                        <!--                        <div class='card-footer'>-->
                        <!--                            <div class='row justify-content-end'>-->
                        <!--                                <div class='col-auto is-animated d-flex'>-->
                        <!--                                    <input type='file' id='fileUpload5' name='fileUpload' @change='handleImage' hidden />-->
                        <!--                                    <button v-if='!image5.loading && modification'-->
                        <!--                                            class='btn&#45;&#45;outline'-->
                        <!--                                            @click='selectFile5'>-->
                        <!--                                        <span>Modifier l'image</span>-->
                        <!--                                        <svg-download />-->
                        <!--                                    </button>-->
                        <!--                                    <button v-if='image5.loading'-->
                        <!--                                            class=''-->
                        <!--                                            type='button'-->
                        <!--                                            disabled>-->
                        <!--                                        <span>Upload en cours...</span>-->
                        <!--                                        <figure class='spin&#45;&#45;infinite ml-2'>-->
                        <!--                                            <svg-download />-->
                        <!--                                        </figure>-->
                        <!--                                    </button>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <div class='row justify-content-end text-demat mr-1 mt-1 font-italic'>-->
                        <!--                                <small>Format: JPG/PNG - Max: 300Ko</small>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                </div>
                <div class='col-sm-4'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Image 6</h3>
                            <div class='row justify-content-center'>
                                <div class='col-auto' v-if='detail.image6 && !image6.error'>
                                    <img class='img-fluid' :src="detail.image6" >
                                </div>
                                <div class='col-auto' v-if='!detail.image6 || image6.error'>
                                    <notif type='error'
                                           :text='image6.errorText'/>
                                </div>
                            </div>
                        </div>
                        <!--                        <div class='card-footer'>-->
                        <!--                            <div class='row justify-content-end'>-->
                        <!--                                <div class='col-auto is-animated d-flex'>-->
                        <!--                                    <input type='file' id='fileUpload6' name='fileUpload' @change='handleImage' hidden />-->
                        <!--                                    <button v-if='!image6.loading && modification'-->
                        <!--                                            class='btn&#45;&#45;outline'-->
                        <!--                                            @click='selectFile6'>-->
                        <!--                                        <span>Modifier l'image</span>-->
                        <!--                                        <svg-download />-->
                        <!--                                    </button>-->
                        <!--                                    <button v-if='image6.loading'-->
                        <!--                                            class=''-->
                        <!--                                            type='button'-->
                        <!--                                            disabled>-->
                        <!--                                        <span>Upload en cours...</span>-->
                        <!--                                        <figure class='spin&#45;&#45;infinite ml-2'>-->
                        <!--                                            <svg-download />-->
                        <!--                                        </figure>-->
                        <!--                                    </button>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <div class='row justify-content-end text-demat mr-1 mt-1 font-italic'>-->
                        <!--                                <small>Format: JPG/PNG - Max: 300Ko</small>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal mouvement de stock -->
        <modal title="Mouvement de stock" :display="stockModal" @close="closeModal" >
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-12">
                            <fieldset>
                                <legend>Informations</legend>
                                <div class="form-group row">
                                    <label class='col-3 col-form-label'>Lieu</label>
                                    <div class='col-6'>
                                        <select class='form-control'
                                                v-model='movement.place'
                                                required>
                                            <option v-for='(place, key) in places'
                                                    :key='key'
                                                    :value='place.placeId'>
                                                {{ place.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row" v-if="movement.place === '2'">
                                    <label class='col-3 col-form-label'>Emplacement</label>
                                    <div class='col-6'>
                                        <select class='form-control'
                                                v-model='movement.location'
                                                required>
                                            <option v-for='(location, key) in locations'
                                                    :key='key'
                                                    :value='location.physicalLocationId'>
                                                {{ location.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class='col-3 col-form-label'>Quantité</label>
                                    <div class='col-6'>
                                        <input class='form-control'
                                               type="number"
                                               min="0"
                                               v-model='movement.quantity'
                                               required
                                               disabled>
                                    </div>
                                </div>
                                <input type="text" :value="detail.deviceId" hidden>
                            </fieldset>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn--outline btn--color--danger" @click="closeModal" >
                    <svg-close />
                    <span>Annuler</span>
                </button>
                <button type="button" class="btn--color-danger" @click="addMovement" >
                    <span>Enregistrer</span>
                    <svg-floppy-disk />
                </button>
            </div>
        </modal>
    </section>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import svgComponent from '../../_svg/svgComponents';
import notif from '../../components/Notif.vue';
import { useAuthStore } from '@/stores/auth.store';
import { useProductStore } from '@/stores/product.store';
import { useLoader } from '@/stores/loader';
import modal from '@/components/Modal.vue';
import { DateTime } from 'luxon';

export default {
    name: 'DeviceDetail',
    components: {
        modal,
        ...svgComponent,
        notif,
    },
    data() {
        return {
            detail: null,
            brands: [],
            models: [],
            repairTypes: [],
            capacities: [],
            ramCapacities : [],
            productTypes: [],
            componentTypes: [],
            componentQualities: [],
            colors:[],
            units:[],
            ramUnits:[],
            grades:[],
            status: [
                {
                    value: 1,
                    value2: true,
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success',
                },
                {
                    value: 0,
                    value2: false,
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger',
                },
            ],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            lecture: false,
            modification: false,
            image: {
                loading: false,
                error: false,
                errorText: 'Aucune image',
                number: 1
            },
            image2: {
                loading: false,
                error: false,
                errorText: 'Aucune image',

            },
            image3: {
                loading: false,
                error: false,
                errorText: 'Aucune image',

            },
            image4: {
                loading: false,
                error: false,
                errorText: 'Aucune image',

            },
            image5: {
                loading: false,
                error: false,
                errorText: 'Aucune image',

            },
            image6: {
                loading: false,
                error: false,
                errorText: 'Aucune image',

            },
            movement: {},
            places: [],
            locations: [],
            stockModal: false,
            products: []
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des appareils', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des appareils', 'Lecture');
        if(!this.lecture)
            this.$router.push("/admin/device");
        await this.getDetail();
        await this.getPlaces();
        await this.getBrands();
        await this.getProductTypes();
        // await this.getModels();
        // await this.getProducts();
        await this.getRamUnits();
        await this.getUnits();
        await this.getColors();
        await this.getGrades();
    },
    watch: {
        'detail.deviceId': async function (val, oldVal) {
            if (val !== oldVal)
                await this.getStocks();
        },
        'movement.place': async function (val, oldVal) {
            if (val !== oldVal && val === '2')
                await this.getPhysicalLocations();
        },
        'success' : async function (val, oldVal) {
            if(val !== oldVal && val === true)
                await this.getDetail();
            await this.getStocks();
        },
        'detail.brandId': async function(val, oldVal) {
            if (val !== oldVal)
                await this.getModels();
        },
        'detail.productTypeId': async function(val, oldVal) {
            if (val !== oldVal)
                await this.getModels();
        },
        'detail.modelId' : async function(val, oldVal) {
            if (val !== oldVal)
                await this.getProducts();
        },
        'detail.romUnitId': async function(val, oldVal) {
            if (val !== oldVal) {
                await this.getProducts();
                if(this.detail.romUnitId)
                    await this.getCapacities();
            }
        },
        'detail.romCapacityId': async function(val, oldVal) {
            if (val !== oldVal) {
                await this.getProducts();
            }
        },
        'detail.ramUnitId': async function(val, oldVal) {
            if (val !== oldVal) {
                await this.getProducts();
                if(this.detail.ramUnitId)
                    await this.getRamCapacities();
            }
        },
        'detail.ramCapacityId': async function(val, oldVal) {
            if (val !== oldVal) {
                await this.getProducts();
            }
        },
        'detail.colorId': async function(val, oldVal) {
            if (val !== oldVal)
                await this.getProducts();
        }
    },
    methods: {
        async getDetail() {
            try {
                this.loader.show('load');
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/device/${this.$route.params.id}/detail`,
                );
                this.detail = res.data;
                this.detail.name2 = (this.detail.name).toLowerCase();
                this.detail.name2 = this.detail.name2.charAt(0).toUpperCase()+this.detail.name2.slice(1);
                this.detail.publishedDateStart = this.detail.publishedDateStart ?
                    DateTime.fromISO(res.data.publishedDateStart, { setZone: "Europe/Paris" }).toFormat('yyyy-MM-dd HH:mm:ss') : null;
                this.detail.publishedDateEnd = this.detail.publishedDateEnd ?
                    DateTime.fromISO(res.data.publishedDateEnd, { setZone: "Europe/Paris" }).toFormat('yyyy-MM-dd HH:mm:ss') : null;
            } catch (err) {
                console.log(err);
            } finally {
                this.loader.hide('load');
            }
        },
        async getStocks() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/stocks/device/${this.detail.deviceId}`,
                );
                this.detail.stocks = res.data.length > 0 ? res.data : [{ quantity: 0 }];
            } catch (err) {
                console.log(err);
            }
        },
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                this.places = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getGrades() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/grade`,
                );
                this.grades = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getModels() {
            try {
                if (this.detail.brandId && this.detail.productTypeId) {
                    const res = await this.axios.get(
                        `${window.location.origin}/api/crd/models/brand/${this.detail.brandId}/productType/${this.detail.productTypeId}`,
                    );
                    this.models = res.data;
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getPhysicalLocations() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/physicalLocations/place/${this.movement.place}`,
                );
                this.locations = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getProducts() {
            if (this.productStore.getList.length === 0)
                await this.productStore.getProducts();
            this.products = this.productStore.getList;
            this.products =  this.products.reduce((curr, prod)=>{
                console.log(prod, this.detail.brandId);
                if(prod.brandId === this.detail.brandId && prod.productTypeId === this.detail.productTypeId
                && prod.modelId === this.detail.modelId)
                    curr.push(prod);
                return curr;
            },[]);
            if(this.detail.colorId) {
                this.products =  this.products.reduce((curr, prod)=>{
                    if(prod.colorId === this.detail.colorId)
                        curr.push(prod);
                    return curr;
                },[]);
            }
            if(this.detail.romCapacityId) {
                this.products =  this.products.reduce((curr, prod)=>{
                    if(prod.capacityId === this.detail.romCapacityId)
                        curr.push(prod);
                    return curr;
                },[]);
            }
            this.products.map(product => {
                product.name = (product.brandName + ' ' + product.modelName + ' ' + product.color + ' ' + product.rom).toLowerCase();
                product.name += product.ram ? ' (' + product.ram + ' de ram)'.toLowerCase() : '';
                product.name = product.name.charAt(0).toUpperCase() + product.name.slice(1);
            });
        },
        async getBrands() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.brands = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getProductTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/productType`,
                );
                this.productTypes = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getUnits() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/unit`,
                );
                this.units = res.data;
            } catch (err) {
                console.log(err);
            }
        },async getRamUnits() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/unit`,
                );
                this.ramUnits = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getCapacities() {
            try {
                if (typeof this.detail.romUnitId !== 'undefined') {
                    const res = await this.axios.get(
                        `${window.location.origin}/api/crd/capacities/unit/${this.detail.romUnitId}`,
                    );
                    this.capacities = res.data.filter(c => c.status === true);
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getRamCapacities() {
            try {
                if (typeof this.detail.romUnitId !== 'undefined') {
                    const res = await this.axios.get(
                        `${window.location.origin}/api/crd/capacities/unit/${this.detail.ramUnitId}`,
                    );
                    this.ramCapacities = res.data.filter(c => c.status === true);
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getColors() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/color`,
                );
                this.colors = res.data.filter(c => c.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        getHistory() {
            this.$router.push(`/admin/stock/${this.detail.stocks[0].stockId}`);
        },
        selectFile() {
            this.image.number = 1;
            document.getElementById('fileUpload1').click();
        },
        selectFile2() {
            this.image.number = 2;
            document.getElementById('fileUpload2').click();
        },
        selectFile3() {
            this.image.number = 3;
            document.getElementById('fileUpload3').click();
        },
        selectFile4() {
            this.image.number = 4;
            document.getElementById('fileUpload4').click();
        },
        selectFile5() {
            this.image.number = 5;
            document.getElementById('fileUpload5').click();
        },
        selectFile6() {
            this.image.number = 6;
            document.getElementById('fileUpload6').click();
        },
        handleImage(e) {
            const img = e.target.files[0];

            if (['image/jpeg', 'image/png'].indexOf(img.type) === -1) {
                if (this.image.number === 1) {
                    this.image.error = true;
                    return this.image.errorText = 'Veuillez uploader une image au format PNG ou JPEG.';
                } else if (this.image.number === 2) {
                    this.image2.error = true;
                    return this.image2.errorText = 'Veuillez uploader une image au format PNG ou JPEG.';
                } else if (this.image.number === 3) {
                    this.image3.error = true;
                    return this.image3.errorText = 'Veuillez uploader une image au format PNG ou JPEG.';
                } else if (this.image.number === 4) {
                    this.image4.error = true;
                    return this.image4.errorText = 'Veuillez uploader une image au format PNG ou JPEG.';
                } else if (this.image.number === 5) {
                    this.image5.error = true;
                    return this.image5.errorText = 'Veuillez uploader une image au format PNG ou JPEG.';
                }else {
                    this.image6.error = true;
                    return this.image6.errorText = 'Veuillez uploader une image au format PNG ou JPEG.';
                }
            }

            if (img.size > 300000) {
                if (this.image.number === 1) {
                    this.image.error = true;
                    return this.image.errorText = 'Veuillez uploader une image inférieure à 300ko.';
                } else if (this.image.number === 2) {
                    this.image2.error = true;
                    return this.image2.errorText = 'Veuillez uploader une image inférieure à 300ko.';
                } else if (this.image.number === 3) {
                    this.image3.error = true;
                    return this.image3.errorText = 'Veuillez uploader une image inférieure à 300ko.';
                } else if (this.image.number === 4) {
                    this.image4.error = true;
                    return this.image4.errorText = 'Veuillez uploader une image inférieure à 300ko.';
                } else if (this.image.number === 5) {
                    this.image5.error = true;
                    return this.image5.errorText = 'Veuillez uploader une image inférieure à 300ko.';
                }else {
                    this.image6.error = true;
                    return this.image6.errorText = 'Veuillez uploader une image inférieure à 300ko.';
                }
            }

            const reader = new FileReader();

            reader.onload = (e) => {
                this.uploadImage(e.target.result);
            };

            reader.readAsDataURL(img);
        },
        async uploadImage(image) {
            if (this.image.number === 1) {
                this.image.error = false;
                this.image.loading = true;
            } else if (this.image.number === 2) {
                this.image2.error = false;
                this.image2.loading = true;
            } else if (this.image.number === 3) {
                this.image3.error = false;
                this.image3.loading = true;
            } else if (this.image.number === 4) {
                this.image4.error = false;
                this.image4.loading = true;
            } else if (this.image.number === 5) {
                this.image5.error = false;
                this.image5.loading = true;
            }else {
                this.image6.error = false;
                this.image6.loading = true;
            }

            try {
                const data = {
                    newImage: image,
                    number : this.image.number
                };

                const res = await this.axios.post(`${window.location.origin}/api/crd/device/${this.detail.deviceId}/image`, data);
                this.detail.image1 = res.data.image1;
                this.detail.image2 = res.data.image2;
                this.detail.image3 = res.data.image3;
                this.detail.image4 = res.data.image4;
                this.detail.image5 = res.data.image5;
                this.detail.image6 = res.data.image6;
            } catch (err) {
                if (this.image.number === 1) {
                    this.image.error = true;
                } else  if (this.image.number === 2) {
                    this.image2.error = true;
                } else  if (this.image.number === 3) {
                    this.image3.error = true;
                } else  if (this.image.number === 4) {
                    this.image4.error = true;
                } else  if (this.image.number === 5) {
                    this.image5.error = true;
                } else {
                    this.image6.error = true;
                }
            } finally {
                this.image.loading = false;
                this.image2.loading = false;
                this.image3.loading = false;
                this.image4.loading = false;
                this.image5.loading = false;
                this.image6.loading = false;
            }
        },
        openStockModal() {
            this.movement.quantity = 1;
            this.movement.type = 'in';
            this.movement.oldPlace = this.detail.stocks[0].placeId;
            this.qrcode = null;
            this.stockModal = true;
        },
        closeModal() {
            this.movement = {};
            this.stockModal = false;
        },
        async addMovement() {
            try {
                const res = await this.axios.post(
                    `${window.location.origin}/api/crd/stock/device/${this.detail.deviceId}`,
                    this.movement
                );
                this.detail.stocks = res.data;
                this.success = true;
            } catch (err) {
                console.log(err);
                this.error = true;
                this.msgError = err.response.data.message;
            } finally {
                this.movement = {};
                this.closeModal();
            }
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;

                try {
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/device/${this.detail.deviceId}`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    this.detail = res.data;
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
    },
    setup: () => ({
        v$: useVuelidate(),
        authStore: useAuthStore(),
        productStore: useProductStore(),
        loader: useLoader()
    }),
    validations() {
        return {
            detail: {
                $autoDirty: true,
                serialNumber: { required },
                brandId: { required },
                productTypeId: { required },
                modelId: { required },
                productId: { required },
                gradeId: { required }
            },
        };
    },
};
</script>

<style scoped>
[v-cloak] {
    display: none;
}
</style>
